// src/components/HomePage.js
import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import SendSMSForm from './SendSMSForm';
import ScheduleSMSForm from './ScheduleSMSForm';  
import BulkSMSForm from './BulkSMSForm';  
import AddBirthdayForm from './AddBirthdayForm';
import ExcelBirthdayUploadForm from './ExcelBirthdayUploadForm';
import UpdateBirthdayForm from './UpdateBirthdayForm';
import GetBirthdayByPhone from './GetBirthdayByPhone';
import BirthdayByMonthForm from './BirthdayByMonthForm';
import DownloadExcelAllBirthdayForm from './DownloadExcelAllBirthdayForm';
import DeleteBirthdayByPhoneForm from './DeleteBirthdayByPhoneForm';
import { config } from '../config/config';  // Import the config
import '../styles/HomePage.css'; 

const HomePage = () => {
  const [selectedForm, setSelectedForm] = useState('');
  const [greeting, setGreeting] = useState('');
  const [countryFlag, setCountryFlag] = useState('');
  const [time, setTime] = useState('');

  // Function to update the greeting and time based on the current time
  const updateGreetingAndTime = () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();

    // Determine the greeting based on the time of day
    if (hours < 12) {
      setGreeting('Good Morning');
    } else if (hours < 18) {
      setGreeting('Good Afternoon');
    } else {
      setGreeting('Good Night');
    }

    // Get time based on the time zone
    const timeZone = localStorage.getItem('timeZone');
    if (timeZone) {
      const options = {
        timeZone: timeZone,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };

      const timeFormatter = new Intl.DateTimeFormat('en-US', options);
      setTime(timeFormatter.format(currentTime));
    }
  };

  // Fetch the country, flag, and time zone data from localStorage
  useEffect(() => {
    const country = localStorage.getItem('country');
    const flag = localStorage.getItem('flag');
    const timeZone = localStorage.getItem('timeZone');

    if (country && flag && timeZone) {
      setCountryFlag(flag);
      updateGreetingAndTime(); // Update greeting and time based on the stored values

      // Set up an interval to update the time every second
      const intervalId = setInterval(updateGreetingAndTime, 1000);

      // Clean up the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, []);

  const renderForm = () => {
    switch (selectedForm) {
      case 'send-sms':
        return <SendSMSForm className="form-section" />;
      case 'schedule-sms':
        return <ScheduleSMSForm className="form-section" />;
      case 'bulk-sms':
        return <BulkSMSForm className="form-section" />;
      case 'upload-excel-birthday-schedule':
        return <ExcelBirthdayUploadForm className="form-section" />;
      case 'add-birthday-schedule':
        return <AddBirthdayForm className="form-section" />;
      case 'update-birthday-schedule':
        return <UpdateBirthdayForm className="form-section" />;
      case 'get-birthday-schedule':
        return <GetBirthdayByPhone className="form-section" />;
      case 'get-birthday-names':
        return <BirthdayByMonthForm className="form-section" />;
      case 'download-excel-alldata':
        return <DownloadExcelAllBirthdayForm className="form-section" />;
      case 'delete-birthday-schedule':
        return <DeleteBirthdayByPhoneForm className="form-section" />;
      default:
        return (
          <div className="centered-message">
            <h3>Please select an option from the sidebar.</h3>
          </div>
        );
    }
  };

  return (
    <div className="home-page-container">
      <Sidebar setSelectedForm={setSelectedForm} />
      <div className="content-container">
        {/* Header with Greeting, Country Flag, and Time */}
        <div className="header">
          <div className="greeting">
            <h1>{greeting}</h1>
          </div>
          <div className="location-info">
            {config.countryFlagEnabledOnLocation && countryFlag && (
              <img src={countryFlag} alt="Country Flag" className="country-flag" />
            )}
            <p>{time}</p>
          </div>
        </div>

        <div className="form-container">
          {renderForm()}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
