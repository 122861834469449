import React, { useState } from 'react';
import { FaBars, FaChevronDown, FaChevronUp, FaSms, FaEnvelope, FaBirthdayCake, FaChevronRight, FaSignOutAlt } from 'react-icons/fa'; // Import the logout icon
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirect
import '../styles/Sidebar.css';

const Sidebar = ({ setSelectedForm }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [openSection, setOpenSection] = useState(null); // Track the currently open section
  const navigate = useNavigate(); // Initialize useNavigate

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Toggle the section, but collapse the previously opened one
  const toggleSection = (sectionName) => {
    setOpenSection((prevState) => (prevState === sectionName ? null : sectionName));
  };

  // Handle item click and form selection
  const handleItemClick = (formType) => {
    setSelectedForm(formType);
  };

  // Handle logout and navigate to login page
  const handleLogout = () => {
    // Redirect to the login page
    navigate('/login');  // Ensure this correctly routes to the login page
  };

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <button onClick={toggleSidebar} className="toggle-btn">
        {isCollapsed ? <FaChevronRight /> : <FaBars />} {/* Toggle icon when collapsed */}
      </button>
      <div className="sidebar-items">
        
        {/* Notification Service with SMS Icon */}
        <div>
          <h3 onClick={() => toggleSection('notificationService')}>
            <FaSms /> {/* SMS Icon */}
            <span>Notification Service</span> {/* Text is hidden when collapsed */}
            {openSection === 'notificationService' ? <FaChevronUp /> : <FaChevronDown />}
          </h3>
          {openSection === 'notificationService' && (
            <ul className="subitems">
              <li onClick={() => handleItemClick('send-sms')}>Send SMS</li>
              <li onClick={() => handleItemClick('schedule-sms')}>Schedule SMS</li>
              <li onClick={() => handleItemClick('bulk-sms')}>Bulk SMS</li>
            </ul>
          )}
        </div>

        {/* Email Service */}
        <div>
          <h3 onClick={() => toggleSection('emailService')}>
            <FaEnvelope /> {/* Email Icon */}
            <span>Email Service</span>
            {openSection === 'emailService' ? <FaChevronUp /> : <FaChevronDown />}
          </h3>
          {openSection === 'emailService' && (
            <ul className="subitems">
              <li onClick={() => handleItemClick('send-email')}>Send Email</li>
              <li onClick={() => handleItemClick('schedule-email')}>Schedule Email</li>
              <li onClick={() => handleItemClick('bulk-email')}>Bulk Email</li>
            </ul>
          )}
        </div>

        {/* Birthday Notification Service */}
        <div>
          <h3 onClick={() => toggleSection('birthdayNotificationService')}>
            <FaBirthdayCake /> {/* Birthday Icon */}
            <span>Birthday Notification Service</span>
            {openSection === 'birthdayNotificationService' ? <FaChevronUp /> : <FaChevronDown />}
          </h3>
          {openSection === 'birthdayNotificationService' && (
            <ul className="subitems">
              <li onClick={() => handleItemClick('upload-excel-birthday-schedule')}>Upload Excel for Bulk</li>
              <li onClick={() => handleItemClick('add-birthday-schedule')}>Add Single Birthday Schedule</li>
              <li onClick={() => handleItemClick('update-birthday-schedule')}>Update Birthday Schedule</li>
              <li onClick={() => handleItemClick('get-birthday-schedule')}>Get Birthday Schedule by Phone</li>
              <li onClick={() => handleItemClick('get-birthday-names')}>Get Birthdays by Date</li>
              <li onClick={() => handleItemClick('download-excel-alldata')}>Download All Birthday Data</li>
              <li onClick={() => handleItemClick('delete-birthday-schedule')}>Delete Birthday Schedule by Phone</li>
            </ul>
          )}
        </div>
        
        {/* Logout Button */}
        <div className="logout-btn" onClick={handleLogout}>
          <FaSignOutAlt /> {/* Logout Icon */}
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
