import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../styles/BirthdayNotificationForm.css';
import { apiConfig } from '../config/config'; // Assuming you have apiConfig for base URL

const DeleteBirthdayByPhoneForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      // Encode the phone number to handle the "+" sign and other special characters
      const encodedPhoneNumber = encodeURIComponent(phoneNumber); 

      const response = await axios.delete(
        `${apiConfig.baseURL}/public/api/birthday-notification/delete-birthday-schedule/${encodedPhoneNumber}`,
        {
          headers: {
            'Accept': '*/*',
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: 'Birthday schedule deleted successfully!',
          icon: 'success',
          confirmButtonText: 'OK',
        });

        setPhoneNumber('');  // Clear the input field after successful deletion
      } else {
        throw new Error('Failed to delete birthday schedule');
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: `Error: ${error.message}`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="birthday-form-container">
      <h3>Delete Birthday Schedule</h3>
      <form onSubmit={handleDelete}>
        <input
          type="text"
          placeholder="Phone Number (+CountryCode)"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
          className="input-field"
        />
<button 
  type="submit" 
  disabled={isLoading} 
  className="submit-btn" 
  style={{ backgroundColor: 'red', color: 'white' }}
>
  {isLoading ? 'Deleting...' : 'Delete Schedule'}
</button>

      </form>
    </div>
  );
};

export default DeleteBirthdayByPhoneForm;
