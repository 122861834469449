import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../styles/BirthdayNotificationForm.css';
import { apiConfig } from '../config/config';

const UpdateBirthdayForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name,
      birthday,
      contactNumber,
    };

    setLoading(true);

    try {
      await axios.patch(
        `${apiConfig.baseURL}/api/birthday-notification/update-birthday-schedule/${phoneNumber}`,
        data,
        { headers: { 'Content-Type': 'application/json' } }
      );

      setLoading(false);

      Swal.fire({
        title: 'Success!',
        text: 'Birthday schedule updated successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      setLoading(false);

      Swal.fire({
        title: 'Error!',
        text: 'Failed to update birthday schedule!',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="birthday-form-container">
      <h3>Update Birthday Schedule</h3>
      <input
        type="text"
        placeholder="Phone Number (+CountryCode)"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        required
        className="input-field"
      />
      <input
        type="text"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        className="input-field"
      />
      <input
        type="date"
        placeholder="Birthday"
        value={birthday}
        onChange={(e) => setBirthday(e.target.value)}
        required
        className="input-field"
      />
      <input
        type="text"
        placeholder="Contact Number"
        value={contactNumber}
        onChange={(e) => setContactNumber(e.target.value)}
        required
        className="input-field"
      />
      <button type="submit" disabled={loading} className="submit-btn">
        {loading ? 'Updating...' : 'Update Birthday'}
      </button>
    </form>
  );
};

export default UpdateBirthdayForm;
