import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../styles/BirthdayNotificationForm.css';
import { apiConfig } from '../config/config';

const AddBirthdayForm = () => {
  const [name, setName] = useState('');
  const [birthday, setBirthday] = useState(''); // Date format will be handled here
  const [contactNumber, setContactNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [submittedName, setSubmittedName] = useState(''); // To store the submitted name

  // Function to convert date from YYYY-MM-DD to DD-MM-YYYY format
  const formatDate = (date) => {
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Convert birthday date from YYYY-MM-DD to DD-MM-YYYY format
    const formattedBirthday = formatDate(birthday);

    const data = {
      name,
      birthday: formattedBirthday,  // Use the formatted date
      contactNumber,
    };

    setLoading(true); // Disable the button and show loading state

    try {
      const response = await axios.post(
        `${apiConfig.baseURL}/public/api/birthday-notification/add-birthday-schedule`,
        data,
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.status === 200) {
        setSubmittedName(name); // Set the name dynamically after successful submission
        setLoading(false); // Re-enable the button

        Swal.fire({
          title: 'Success!',
          text: `Birthday schedule for ${name} added successfully!`,
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } else {
        throw new Error('Failed to add birthday schedule');
      }
    } catch (error) {
      setLoading(false); // Re-enable the button on error

      Swal.fire({
        title: 'Error!',
        text: 'Failed to add birthday schedule!',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <div className="birthday-form-container">
      <h3>Add Birthday Schedule</h3>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="input-field"
        />
        {/* Date picker: The browser automatically provides the correct format */}
        <input
          type="date"
          placeholder="Birthday"
          value={birthday}
          onChange={(e) => setBirthday(e.target.value)}  // This will keep the value in YYYY-MM-DD format
          required
          className="input-field"
        />
        <input
          type="text"
          placeholder="Contact Number"
          value={contactNumber}
          onChange={(e) => setContactNumber(e.target.value)}
          required
          className="input-field"
        />
        <button type="submit" disabled={loading} className="submit-btn">
          {loading ? 'Adding...' : 'Add Birthday'}
        </button>
      </form>

      {/* Display the name after successful submission */}
      {submittedName && (
        <div className="success-message">
          <p>Birthday schedule for <strong>{submittedName}</strong> added successfully!</p>
        </div>
      )}
    </div>
  );
};

export default AddBirthdayForm;
