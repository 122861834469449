// src/config/config.js
export const apiConfig = {
  baseURL: 'https://annadaneshwar-notification-service.azurewebsites.net',
};

export const config = {
  countryFlagEnabledOnLocation: true, // Set this to true to enable flag display, false to disable
  underDevelopment: false,   // Other flags as needed
  videoUrl: '/Website-is-in-development.mp4',
};
