import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../styles/SendSMSForm.css'; 
import { apiConfig } from '../config/config';

const ScheduleSMSForm = () => {
  const [to, setTo] = useState('');
  const [message, setMessage] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const phoneRegex = /^\+\d{1,3}\d{7,14}$/;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!phoneRegex.test(to)) {
      setError("Phone number must start with country code (e.g., +1, +91) and include 8-15 digits.");
      return;
    }

    if (!scheduleTime) {
      setError("Please provide a valid schedule time.");
      return;
    }

    setError('');
    setLoading(true);

    try {
      // Send the POST request to schedule the SMS
      await axios.post(
       `${apiConfig.baseURL}/public/api/sms/schedule?to=${encodeURIComponent(to)}&dateTime=${encodeURIComponent(scheduleTime)}&message=${encodeURIComponent(message)}`,
        {},
        { headers: { accept: '*/*' } }
      );
      setLoading(false);  // Stop loading

      Swal.fire({
        title: 'Success!',
        text: 'SMS Scheduled Successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      setLoading(false);

      Swal.fire({
        title: 'Error!',
        text: 'Error scheduling SMS!',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Schedule SMS</h2>
      <input
        type="text"
        placeholder="Recipient's Phone Number (+CountryCode)"
        value={to}
        onChange={(e) => setTo(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
      />
      <input
        type="datetime-local"
        value={scheduleTime}
        onChange={(e) => setScheduleTime(e.target.value)}
        required
      />
      {error && <p className="error-message">{error}</p>}
      <button type="submit" disabled={loading}>
        {loading ? 'Scheduling...' : 'Schedule SMS'}
      </button>
    </form>
  );
};

export default ScheduleSMSForm;
