import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../styles/BirthdayNotificationForm.css';
import { apiConfig } from '../config/config';

const GetBirthdayByPhone = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [birthdaySchedule, setBirthdaySchedule] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true); // Set loading to true when the button is clicked

    try {
      const response = await axios.get(
        `${apiConfig.baseURL}/public/api/birthday-notification/get-birthday-schedule/${encodeURIComponent(phoneNumber)}`,
        {
          headers: {
            'Accept': 'application/json',
          },
        }
      );

      // If the response is successful, update the state
      setBirthdaySchedule(response.data);

      Swal.fire({
        title: 'Success!',
        text: 'Birthday schedule fetched successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      // Handle errors
      Swal.fire({
        title: 'Error!',
        text: `Error: ${error.response?.data?.message || error.message}`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setIsLoading(false); // Set loading to false after the response
    }
  };

  return (
    <div className="birthday-form-container">
      <h3>Get Birthday Schedule</h3>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
          className="input-field"
        />
        <button type="submit" className="submit-btn" disabled={isLoading}>
          {isLoading ? 'Getting...' : 'Get Schedule'}
        </button>
      </form>

      {birthdaySchedule && (
        <div>
          <p><strong>Name:</strong> {birthdaySchedule.name}</p>
          <p><strong>Birthday:</strong> {birthdaySchedule.birthday}</p>
          <p><strong>Contact:</strong> {birthdaySchedule.contactNumber}</p>
        </div>
      )}
    </div>
  );
};

export default GetBirthdayByPhone;
