import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import '../styles/BirthdayNotificationForm.css';
import { apiConfig } from '../config/config'; // Assuming apiConfig is correctly imported

const BirthdayByMonthForm = () => {
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [birthdayNames, setBirthdayNames] = useState([]); // Ensure it's initialized as an empty array
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // State to track loading

  // List of months for dropdown
  const months = [
    { value: '', label: 'Select Month' },
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  // Function to update days based on selected month
  const updateDaysInMonth = (month) => {
    const days31 = Array.from({ length: 31 }, (_, i) => i + 1);
    const days30 = Array.from({ length: 30 }, (_, i) => i + 1);
    const daysFeb = Array.from({ length: 28 }, (_, i) => i + 1);

    switch (month) {
      case '01': // January
      case '03': // March
      case '05': // May
      case '07': // July
      case '08': // August
      case '10': // October
      case '12': // December
        setDaysInMonth(days31);
        break;
      case '04': // April
      case '06': // June
      case '09': // September
      case '11': // November
        setDaysInMonth(days30);
        break;
      case '02': // February
        setDaysInMonth(daysFeb);
        break;
      default:
        setDaysInMonth([]);
        break;
    }
  };

  useEffect(() => {
    if (month) {
      updateDaysInMonth(month);
    }
  }, [month]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate that both month and day are selected
    if (!month || !day) {
      Swal.fire({
        title: 'Error!',
        text: 'Please select both the month and the date.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }

    // Set loading state to true when fetching data
    setIsLoading(true);

    try {
      const date = `${day}-${month}`;
      const response = await axios.get(`${apiConfig.baseURL}/public/api/birthday-notification/get-birthday-names`, {
        params: { date },
      });

      if (response.status === 200) {
        const data = response.data || []; // Ensure data is always an array
        if (data.length > 0 && Array.isArray(data)) {
          // Check if the data contains the message "No birthdays found on this date."
          if (data.includes("No birthdays found on this date.")) {
            Swal.fire({
              title: 'No Birthdays Found!',
              text: 'No birthdays found for the selected month and date.',
              icon: 'info',
              confirmButtonText: 'OK',
            });
            setBirthdayNames([]); // Clear any previous data
          } else {
            setBirthdayNames(data); // Set names if they exist
          }
        } else {
          // Fallback if the data array is empty or the response is malformed
          Swal.fire({
            title: 'Error!',
            text: 'Failed to fetch valid birthday data.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to fetch birthday names.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
      
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: `Error: ${error.message}`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      // Reset loading state once the request finishes
      setIsLoading(false);
    }
  };

  return (
    <div className="birthday-form-container">
      <h3>Get Birthday Names by Month and Date</h3>
      <form onSubmit={handleSubmit}>
        <div className="select-container">
          <select
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            className="input-field"
          >
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>

          <select
            value={day}
            onChange={(e) => setDay(e.target.value)}
            className="input-field"
            disabled={!month}  // Disable day selection until month is selected
          >
            <option value="">Select Day</option>
            {daysInMonth.map((day) => (
              <option key={day} value={String(day).padStart(2, '0')}>
                {String(day).padStart(2, '0')}
              </option>
            ))}
          </select>
        </div>

        <button type="submit" className="submit-btn" disabled={isLoading}>
          {isLoading ? 'Fetching Names...' : 'Get Names'} {/* Dynamically change text */}
        </button>
      </form>

      {/* Only show the table if there are names to display */}
      {Array.isArray(birthdayNames) && birthdayNames.length > 0 && (
        <div className="birthday-table">
          <table>
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {birthdayNames.map((name, index) => (
                <tr key={index}>
                  <td>{name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default BirthdayByMonthForm;
