import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../styles/SendSMSForm.css'; 
import { apiConfig } from '../config/config';

const BulkSMSForm = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setFile(selectedFile);
      setError('');
    } else {
      setError('Please select a valid .xlsx file');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setError('Please upload a file.');
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post(
        `${apiConfig.baseURL}/public/api/sms/upload`,
        formData,
        {
          headers: {
            'accept': '*/*',
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setLoading(false);

      Swal.fire({
        title: 'Success!',
        text: 'Bulk SMS file uploaded successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      setLoading(false);

      Swal.fire({
        title: 'Error!',
        text: 'Error uploading the file!',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Bulk SMS Upload</h2>
      <input
        type="file"
        accept=".xlsx"
        onChange={handleFileChange}
        required
      />
      {error && <p className="error-message">{error}</p>}
      <button type="submit" disabled={loading}>
        {loading ? 'Uploading...' : 'Upload Bulk SMS File'}
      </button>
    </form>
  );
};

export default BulkSMSForm;
