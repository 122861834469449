import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'; 
import { apiConfig } from '../config/config';

const DownloadExcelAllBirthdayForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${apiConfig.baseURL}/public/api/birthday-notification/download-excel/alldata`,
        { responseType: 'blob' } // Specify that the response is a blob
      );

      if (response.status === 200) {
        // Create a Blob and trigger the download
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'birthday_schedule.xlsx'; // Set the default filename for the download
        a.click();
        window.URL.revokeObjectURL(url);

        // Show success message using SweetAlert
        Swal.fire({
          title: 'Success!',
          text: 'Excel file downloaded successfully!',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } else {
        throw new Error('Failed to download Excel file');
      }
    } catch (error) {
      // Show error message using SweetAlert
      Swal.fire({
        title: 'Error!',
        text: `Error: ${error.message}`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h3>Download All Birthday Data</h3>
      <button
        onClick={handleDownload}
        disabled={isLoading}
        className="small-btn" // Add a class for small button size
      >
        {isLoading ? 'Downloading...' : 'Download Excel'}
      </button>
    </div>
  );
};

export default DownloadExcelAllBirthdayForm;
