import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../styles/BirthdayNotificationForm.css';
import { apiConfig } from '../config/config';

const ExcelBirthdayUploadForm = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      Swal.fire({
        title: 'Error!',
        text: 'Please upload a file!',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post(
        `${apiConfig.baseURL}/public/api/birthday-notification/upload-excel-birthday-schedule`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      setLoading(false);

      Swal.fire({
        title: 'Success!',
        text: 'Birthday schedule uploaded successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      setLoading(false);

      Swal.fire({
        title: 'Error!',
        text: 'Failed to upload the file!',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="birthday-form-container">
      <h3>Upload Birthday Excel Schedule</h3>
      <input
        type="file"
        accept=".xlsx"
        onChange={handleFileChange}
        className="input-field"
      />
      <button type="submit" disabled={loading} className="submit-btn">
        {loading ? 'Uploading...' : 'Upload'}
      </button>
    </form>
  );
};

export default ExcelBirthdayUploadForm;
