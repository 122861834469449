// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import HomePage from './components/HomePage';
import Footer from './components/Footer';  // Import Footer
import { config } from './config/config';
import './App.css';

const App = () => {
  const [isUnderDevelopment, setIsUnderDevelopment] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setIsUnderDevelopment(config.underDevelopment);
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="app-content">
        <Routes>
          <Route
            path="/"
            element={isUnderDevelopment ? (
              <div className="under-development-page">
                <video autoPlay muted loop className="video-background">
                  <source src={config.videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="under-development-overlay">
                  <h1>Under Development. Please try again later.</h1>
                </div>
              </div>
            ) : (
              <LoginPage />
            )}
          />
          <Route path="/login" element={<LoginPage />} />  {/* Ensure login route is correctly set */}
          <Route path="/home" element={<HomePage />} />
        </Routes>
        <Footer /> {/* Footer on all pages */}
      </div>
    </Router>
  );
};

export default App;  // Ensure default export
