import React, { useState, useEffect } from 'react'; 
import { useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import Lottie from 'lottie-react';
import animationData from '../animations/loginAnimation.json';
import { config } from '../config/config'; // Import the config
import '../styles/LoginPage.css';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Function to request location permission
  const requestLocationPermission = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetchLocationDetails(latitude, longitude);
        },
        () => {
          SweetAlert.fire({
            title: 'Error!',
            text: 'Location permission denied',
            icon: 'error',
          });
        }
      );
    } else {
      SweetAlert.fire({
        title: 'Error!',
        text: 'Geolocation is not supported by this browser.',
        icon: 'error',
      });
    }
  };

  // Function to fetch location details
  const fetchLocationDetails = (latitude, longitude) => {
    if (!config.countryFlagEnabled) {
      navigate('/home');  // Skip location fetch if disabled
      return;
    }
    const apiKey = 'b3fb9109738b40b1a42a0a895b3938d0';
    const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const country = data.results[0].components.country;
        const flag = `https://flagcdn.com/w320/${data.results[0].components.country_code.toLowerCase()}.png`;
        const timeZone = data.results[0].annotations.timezone.name;

        // Save location data if enabled
        localStorage.setItem('country', country);
        localStorage.setItem('flag', flag);
        localStorage.setItem('timeZone', timeZone);

        navigate('/home');
      })
      .catch(() => {
        SweetAlert.fire({
          title: 'Error!',
          text: 'Could not fetch location details',
          icon: 'error',
        });
      });
  };

  // Request location permission if enabled in config
  useEffect(() => {
    if (config.enableLocationBasedFlag) {
      requestLocationPermission(); // Call the requestLocationPermission function
    }
  });  // Empty dependency array to only run on mount

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === 'Admin' && password === 'Admin') {
      SweetAlert.fire({
        title: 'Success!',
        text: 'Login successful',
        icon: 'success',
      }).then(() => {
        if (config.enableLocationBasedFlag) {
          requestLocationPermission(); // Request location permission after successful login
        } else {
          navigate('/home'); // Skip location fetching if disabled
        }
      });
    } else {
      SweetAlert.fire({
        title: 'Error!',
        text: 'Invalid credentials',
        icon: 'error',
      });
    }
  };

  return (
    <div className="login-page">
      <div className="lottie-container">
        <Lottie animationData={animationData} loop={true} />
      </div>
      <form onSubmit={handleLogin}>
        <h2 id="login-header">Login</h2>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default LoginPage;
