import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';  // Import SweetAlert2 for alerts
import '../styles/SendSMSForm.css';  // Ensure correct CSS import
import { apiConfig } from '../config/config';

const SendSMSForm = () => {
  const [to, setTo] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const phoneRegex = /^\+\d{1,3}\d{7,14}$/;  // Regex for phone number validation

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate phone number
    if (!phoneRegex.test(to)) {
      setError("Phone number must start with country code (e.g., +1, +91) and include 8-15 digits.");
      return;
    }

    setError('');  // Clear error if validation passes
    setLoading(true);  // Start loading state

    try {
      await axios.post(
        `${apiConfig.baseURL}/public/api/sms/send?to=${encodeURIComponent(to)}&message=${encodeURIComponent(message)}`,
        {},
        { headers: { accept: '*/*' } }
      );
      setLoading(false);  // Stop loading

      Swal.fire({
        title: 'Success!',
        text: 'SMS Sent Successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      setLoading(false);  // Stop loading

      Swal.fire({
        title: 'Error!',
        text: 'Error sending SMS!',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Send SMS</h2>
      <input
        type="text"
        placeholder="Recipient's Phone Number (+CountryCode)"
        value={to}
        onChange={(e) => setTo(e.target.value)}
        required
      />
      {error && <p className="error-message">{error}</p>}
      <input
        type="text"
        placeholder="Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
      />
      <button type="submit" disabled={loading}>
        {loading ? 'Sending...' : 'Send SMS'}
      </button>
    </form>
  );
};

export default SendSMSForm;
